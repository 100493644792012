import React from 'react';
import './Style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './NavbarComponent';


function HomePage() {
  return (
    <>
      <NavbarComponent />
    </>
  )
}

export default HomePage



